import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

import Loading from "../components/Loading";
import Layout from "../components/layout";
import Video from "../components/Video";

export const query = graphql`
  query {
    allContentfulAboutQuiz {
      nodes {
        headerTitle
        subHeader
        headerText {
          headerText
        }
        section1Title
        section1SubHeader
        section1Text {
          section1Text
        }
        block1Image {
          fluid {
            src
          }
        }
        block1Title
        block1Text
        block2Image {
          fluid {
            src
          }
        }
        block2Title
        block2Text
        block3Image {
          fluid {
            src
          }
        }
        block3Title
        block3Text
        block4Image {
          fluid {
            src
          }
        }
        block4Title
        block4Text
        section2Title
        section2Paragrah {
          section2Paragrah
        }
        section2Block1Title
        section2Block1Paragraph {
          section2Block1Paragraph
        }
        section2Block2Title
        section2Block2Paragraph {
          section2Block2Paragraph
        }
        section3Title
        section3Block1Title
        section3Block1Paragraph
        section3Block2TItle
        section3Block2Paragraph
        section3Block3Title
        section3Block3Paragraph
        section3Block4Title
        section3Block4Paragraph
      }
    }
  }
`;

const Index = ({ data }) => {
  const [homePage] = data.allContentfulAboutQuiz.nodes;
  const [isLoading, setLoading] = useState(true);

  const [accordionObject, setAccordionObject] = useState({
    name: "expression",
    value: true,
  });

  useEffect(() => {
    if (localStorage.getItem("token")) {
      // replace /child-program with /toddler-talk-plan-tutorial url
      //window.location.href = "/child-program";
      window.location.href = '/toddler-talk-plan-tutorial'
    } else {
      setLoading(false);
    }
  });
  return isLoading ? (
    <Loading />
  ) : (
    <Layout>
      <div id="content" className="site-content">
        <div className="content-inner">
          <div className="">
            <div className="row content-row">
              <div id="primary" className="content-area col-12">
                <main id="main" className="site-main">
                  <article
                    id="post-45"
                    className="post-45 page type-page status-publish hentry"
                  >
                    <div className="entry-content clearfix">
                      <div
                        data-elementor-type="wp-page"
                        data-elementor-id="45"
                        className="elementor elementor-45"
                        data-elementor-settings="[]"
                      >
                        <div className="elementor-section-wrap">
                          <div

                            className="elementor-section elementor-top-section elementor-element elementor-element-06481c4 elementor-section-stretched elementor-section-full_width elementor-section-height-min-height elementor-section-items-top elementor-section-height-default bg-quiz"
                            data-id="06481c4"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
                          >
                            <div className="elementor-container elementor-column-gap-default ">
                              <div
                                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c1fe0d6"
                                data-id="c1fe0d6"
                                data-element_type="column"
                              >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-811492b elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                    data-id="811492b"
                                    data-element_type="section"
                                  >
                                    <div className="elementor-container elementor-column-gap-default ">
                                      <div
                                        className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-21ccc75"
                                        data-id="21ccc75"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-04219a5 elementor-widget elementor-widget-cms_image_single"
                                            data-id="04219a5"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-image-single cms-animation-bottom-to-top wow pulse">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width="104"
                                                    height="116"
                                                    src="../images/image-birth.png"
                                                    className="attachment-full size-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-099e703"
                                        data-id="099e703"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-33124d9 elementor-widget elementor-widget-cms_image_single"
                                            data-id="33124d9"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-image-single cms-animation-left-to-right wow fadeInRight">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width="648"
                                                    height="274"
                                                    src="../images/image-clound.png"
                                                    className="attachment-full size-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-c67ed09 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="c67ed09"
                                    data-element_type="section"
                                  >
                                    <div className="elementor-container elementor-column-gap-default ">
                                      <div
                                        className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-16f9d33"
                                        data-id="16f9d33"
                                        data-element_type="column"
                                        data-settings='{"background_background":"classic"}'
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-58c85b7 elementor-widget elementor-widget-cms_heading"
                                            data-id="58c85b7"
                                            data-element_type="widget"
                                            data-widget_type="cms_heading.default"
                                          >
                                            <div className="elementor-widget-container heading-default-font">
                                              <div className="cms-heading-wrapper cms-heading-layout1 wow fadeInUp">
                                                <div className="custom-subheading">
                                                  {homePage.headerTitle}
                                                </div>

                                                <h3 className="custom-heading">
                                                  <span>
                                                    {homePage.subHeader}
                                                  </span>
                                                </h3>

                                                <div className="custom-heading-description">
                                                  {
                                                    homePage.headerText
                                                      .headerText
                                                  }
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-871cfa4 elementor-widget elementor-widget-cms_button"
                                            data-id="871cfa4"
                                            data-element_type="widget"
                                            data-widget_type="cms_button.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div
                                                id="cms_button-871cfa4"
                                                className="cms-button-wrapper cms-button-layout1 wow fadeInUp"
                                              >
                                                <a
                                                  class="btn btn-primary hv-secondary"
                                                  href="/quiz"
                                                  style={{
                                                    marginRight: "40px",
                                                  }}
                                                >
                                                  <span class="btn-text">
                                                    Begin Assessment{" "}
                                                  </span>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-c7e8322"
                                        data-id="c7e8322"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-4d2f573a elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="4d2f573a"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
                          >
                            <div className="elementor-container elementor-column-gap-default ">
                              <div
                                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-25c1041"
                                data-id="25c1041"
                                data-element_type="column"
                              >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    className="elementor-element elementor-element-fb2a341 elementor-widget elementor-widget-cms_heading"
                                    data-id="fb2a341"
                                    data-element_type="widget"
                                    data-widget_type="cms_heading.default"
                                  >
                                    <div className="elementor-widget-container">
                                      <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">
                                        <div className="custom-subheading">
                                          {homePage.section1Title}
                                        </div>

                                        <h3 className="custom-heading">
                                          <span>
                                            {homePage.section1SubHeader}
                                          </span>
                                        </h3>

                                        <div className="custom-heading-description">
                                          {homePage.section1Text.section1Text}
                                        </div>
                                      </div>{" "}
                                    </div>
                                  </div>
                                  <div
                                    className="elementor-element elementor-element-6878b5e1 elementor-widget elementor-widget-cms_image_single"
                                    data-id="6878b5e1"
                                    data-element_type="widget"
                                    data-widget_type="cms_image_single.default"
                                  >
                                    <div className="elementor-widget-container">
                                      <div className="cms-image-single cms-animation-left-to-right wow flash">
                                        <div className="cms-img-box">
                                          <StaticImage
                                            placeholder="none"
                                            width="99"
                                            height="23"
                                            src="../images/image-bee.png"
                                            className="attachment-full size-full"
                                            alt="Kindori"
                                            loading="lazy"
                                          />{" "}
                                        </div>
                                      </div>{" "}
                                    </div>
                                  </div>
                                  <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-73812581 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="73812581"
                                    data-element_type="section"
                                  >
                                    <div className="elementor-container elementor-column-gap-extended ">
                                      <div
                                        className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-102b5c40"
                                        data-id="102b5c40"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-33d5373e elementor-widget elementor-widget-cms_fancy_box"
                                            data-id="33d5373e"
                                            data-element_type="widget"
                                            data-widget_type="cms_fancy_box.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-fancy-box layout1 wow flipInX">
                                                <div className="inner-content">
                                                  <div className="item--icon icon-image">
                                                    <div className="inner-icon">
                                                      <img
                                                        width="800"
                                                        height="800"
                                                        style={{
                                                          maxWidth: 210,
                                                        }}
                                                        src={`https:${homePage.block1Image.fluid.src}`}
                                                        className="attachment-full"
                                                        alt=""
                                                        loading="lazy"
                                                      />{" "}
                                                    </div>
                                                  </div>

                                                  <h3 className="item--title">
                                                    {homePage.block1Title}
                                                  </h3>
                                                  <div className="item--description">
                                                    {homePage.block1Text}
                                                  </div>
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-2b9e5136"
                                        data-id="2b9e5136"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-6fb89582 elementor-widget elementor-widget-cms_fancy_box"
                                            data-id="6fb89582"
                                            data-element_type="widget"
                                            data-widget_type="cms_fancy_box.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-fancy-box layout1 ">
                                                <div className="inner-content">
                                                  <div className="item--icon icon-image">
                                                    <div className="inner-icon">
                                                      <img
                                                        width="800"
                                                        height="800"
                                                        src={`https:${homePage.block2Image.fluid.src}`}
                                                        className="attachment-full"
                                                        alt=""
                                                        loading="lazy"
                                                      />{" "}
                                                    </div>
                                                  </div>

                                                  <h3 className="item--title">
                                                    {homePage.block2Title}
                                                  </h3>
                                                  <div className="item--description">
                                                    {homePage.block2Text}
                                                  </div>
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-22c02e62"
                                        data-id="22c02e62"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-3ddb7e4a elementor-widget elementor-widget-cms_fancy_box"
                                            data-id="3ddb7e4a"
                                            data-element_type="widget"
                                            data-widget_type="cms_fancy_box.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-fancy-box layout1 wow bounce">
                                                <div className="inner-content">
                                                  <div className="item--icon icon-image">
                                                    <div className="inner-icon">
                                                      <img
                                                        width="800"
                                                        height="800"
                                                        src={`https:${homePage.block3Image.fluid.src}`}
                                                        className="attachment-full"
                                                        alt=""
                                                        loading="lazy"
                                                      />{" "}
                                                    </div>
                                                  </div>

                                                  <h3 className="item--title">
                                                    {homePage.block3Title}
                                                  </h3>
                                                  <div className="item--description">
                                                    {homePage.block3Text}
                                                  </div>
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-7e3abd5a"
                                        data-id="7e3abd5a"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-16844e72 elementor-widget elementor-widget-cms_fancy_box"
                                            data-id="16844e72"
                                            data-element_type="widget"
                                            data-widget_type="cms_fancy_box.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-fancy-box layout1 ">
                                                <div className="inner-content">
                                                  <div className="item--icon icon-image">
                                                    <div className="inner-icon">
                                                      <img
                                                        width="800"
                                                        height="800"
                                                        src={`https:${homePage.block4Image.fluid.src}`}
                                                        className="attachment-full"
                                                        alt=""
                                                        loading="lazy"
                                                      />{" "}
                                                    </div>
                                                  </div>

                                                  <h3 className="item--title">
                                                    {homePage.block4Title}
                                                  </h3>
                                                  <div className="item--description">
                                                    {homePage.block4Text}
                                                  </div>
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </section>
                                  <div
                                    className="elementor-element elementor-element-2ded355 elementor-widget elementor-widget-cms_cta"
                                    data-id="2ded355"
                                    data-element_type="widget"
                                    data-widget_type="cms_cta.default"
                                  >
                                    <div className="elementor-widget-container">
                                      <div className="cms-cta layout1 ">
                                        <div className="inner-cms-cta">
                                          <div className="col-content"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-d8ac008 elementor-section-stretched elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="d8ac008"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
                          >
                            <div className="elementor-container elementor-column-gap-default ">
                              <div
                                className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4720d02"
                                data-id="4720d02"
                                data-element_type="column"
                              >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    className="elementor-element elementor-element-7a9a9b1 elementor-widget elementor-widget-cms_image_single"
                                    data-id="7a9a9b1"
                                    data-element_type="widget"
                                    data-widget_type="cms_image_single.default"
                                  >
                                    <div className="elementor-widget-container">
                                      <div className="cms-image-single cms-animation-left-to-right wow zoomIn">
                                        <div className="cms-img-box">
                                          <StaticImage
                                            placeholder="none"
                                            width="616"
                                            height="660"
                                            src="../images/image-left-home.png"
                                            className="attachment-full size-full"
                                            alt=""
                                            loading="lazy"
                                          />{" "}
                                        </div>
                                      </div>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b68ff25"
                                data-id="b68ff25"
                                data-element_type="column"
                              >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    className="elementor-element elementor-element-2750712 elementor-widget elementor-widget-cms_heading"
                                    data-id="2750712"
                                    data-element_type="widget"
                                    data-widget_type="cms_heading.default"
                                  >
                                    <div className="elementor-widget-container">
                                      <div className="cms-heading-wrapper cms-heading-layout1 ">
                                        <h3 className="custom-heading">
                                          <span>{homePage.section2Title}</span>
                                        </h3>

                                        <div className="custom-heading-description">
                                          {
                                            homePage.section2Paragrah
                                              .section2Paragrah
                                          }
                                        </div>
                                      </div>{" "}
                                    </div>
                                  </div>
                                  <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-805af7e elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="805af7e"
                                    data-element_type="section"
                                  >
                                    <div className="elementor-container elementor-column-gap-default ">
                                      <div
                                        className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-0995737"
                                        data-id="0995737"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-3dfa74e elementor-widget elementor-widget-cms_text_box"
                                            data-id="3dfa74e"
                                            data-element_type="widget"
                                            data-widget_type="cms_text_box.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-textbox ">
                                                <h3 className="box-title">
                                                  {homePage.section2Block1Title}
                                                </h3>

                                                <div className="box-excerpt">
                                                  {
                                                    homePage
                                                      .section2Block1Paragraph
                                                      .section2Block1Paragraph
                                                  }
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-416b559"
                                        data-id="416b559"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-83f2dc8 elementor-widget elementor-widget-cms_text_box"
                                            data-id="83f2dc8"
                                            data-element_type="widget"
                                            data-widget_type="cms_text_box.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-textbox ">
                                                <h3 className="box-title">
                                                  {homePage.section2Block2Title}
                                                </h3>

                                                <div className="box-excerpt">
                                                  {
                                                    homePage
                                                      .section2Block2Paragraph
                                                      .section2Block2Paragraph
                                                  }
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </section>
                                  <div
                                    className="elementor-element elementor-element-cba85af elementor-widget elementor-widget-cms_button"
                                    data-id="cba85af"
                                    data-element_type="widget"
                                    data-widget_type="cms_button.default"
                                  >
                                    <div className="elementor-widget-container">
                                      <div
                                        id="cms_button-cba85af"
                                        className="cms-button-wrapper cms-button-layout1 wow flash"
                                      >
                                        <a
                                          className="btn btn-primary hv-secondary"
                                          href="/quiz-about"
                                        >
                                          <span className="btn-text">
                                            Start Today{" "}
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-f6893e0 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                            data-id="f6893e0"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched"}'
                          >
                            <div className="elementor-container elementor-column-gap-default ">
                              <div
                                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9f5f77a"
                                data-id="9f5f77a"
                                data-element_type="column"
                              >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-4522805 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                    data-id="4522805"
                                    data-element_type="section"
                                  >
                                    <div className="elementor-container elementor-column-gap-default ">
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-367b21f"
                                        data-id="367b21f"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-9265126 cms-element elementor-widget elementor-widget-cms_image_single"
                                            data-id="9265126"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-image-single cms-animation-left-to-right ">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width="133"
                                                    height="137"
                                                    src="../images/image-home-2.png"
                                                    className="attachment-full size-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-8869f2a"
                                        data-id="8869f2a"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-8832270 elementor-widget elementor-widget-cms_heading"
                                            data-id="8832270"
                                            data-element_type="widget"
                                            data-widget_type="cms_heading.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">
                                                <div className="custom-subheading">
                                                  How it works
                                                </div>

                                                <h3 className="custom-heading">
                                                  <span>
                                                    {homePage.section3Title}
                                                  </span>
                                                </h3>

                                                <div className="custom-heading-description">
                                                  {/* {
                                                    homePage.section3Paragraph
                                                      .section3Paragraph
                                                  } */}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-6f927b2 elementor-widget elementor-widget-cms_image_single"
                                            data-id="6f927b2"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-image-single animation-none wow flash">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width="99"
                                                    height="23"
                                                    src="../images/image-bee.png"
                                                    className="attachment-full size-full"
                                                    alt="Kindori"
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6be929f"
                                        data-id="6be929f"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-97fbc33 cms-animation-bottom-to-top2 elementor-widget elementor-widget-cms_image_single"
                                            data-id="97fbc33"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-image-single cms-animation-bottom-to-top ">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width="180"
                                                    height="186"
                                                    src="../images/image-home-3.png"
                                                    className="attachment-full size-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-74d00c8 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="74d00c8"
                                    data-element_type="section"
                                  >
                                    <div className="elementor-container elementor-column-gap-default ">
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-0d5a18e"
                                        data-id="0d5a18e"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-72bd156 elementor-widget elementor-widget-cms_fancy_box"
                                            data-id="72bd156"
                                            data-element_type="widget"
                                            data-widget_type="cms_fancy_box.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-fancy-box layout3 box-icon-right ">
                                                <div className="inner-content">
                                                  <div className="item--icon icon-image">
                                                    <div className="inner-icon center-inter-icon">
                                                      <StaticImage
                                                        placeholder="none"
                                                        width="36"
                                                        height="35"
                                                        src="../images/icon-fcb-10.png"
                                                        className="attachment-full"
                                                        alt=""
                                                        loading="lazy"
                                                      />{" "}
                                                    </div>
                                                  </div>
                                                  <div className="item-holder">
                                                    <h3 className="item--title">
                                                      {
                                                        homePage.section3Block1Title
                                                      }
                                                    </h3>
                                                    <div className="item--description">
                                                      {
                                                        homePage.section3Block1Paragraph
                                                      }
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-b49b259 elementor-widget elementor-widget-cms_fancy_box"
                                            data-id="b49b259"
                                            data-element_type="widget"
                                            data-widget_type="cms_fancy_box.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-fancy-box layout3 box-icon-right wow fadeInUp">
                                                <div className="inner-content">
                                                  <div className="item--icon icon-image">
                                                    <div className="inner-icon center-inter-icon">
                                                      <StaticImage
                                                        placeholder="none"
                                                        width="35"
                                                        height="35"
                                                        src="../images/icon-fcb-11.png"
                                                        className="attachment-full"
                                                        alt=""
                                                        loading="lazy"
                                                      />{" "}
                                                    </div>
                                                  </div>
                                                  <div className="item-holder">
                                                    <h3 className="item--title">
                                                      {
                                                        homePage.section3Block2TItle
                                                      }
                                                    </h3>
                                                    <div className="item--description">
                                                      {
                                                        homePage.section3Block2Paragraph
                                                      }
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-5383465"
                                        data-id="5383465"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-344e293 elementor-widget elementor-widget-cms_image_single"
                                            data-id="344e293"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-image-single animation-none wow zoomIn">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width="383"
                                                    height="344"
                                                    src="../images/child-man.png"
                                                    className="attachment-full size-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6aba877"
                                        data-id="6aba877"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-7f512c1 elementor-widget elementor-widget-cms_fancy_box"
                                            data-id="7f512c1"
                                            data-element_type="widget"
                                            data-widget_type="cms_fancy_box.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-fancy-box layout3 box-icon-left wow fadeInDown">
                                                <div className="inner-content">
                                                  <div className="item--icon icon-image">
                                                    <div className="inner-icon center-inter-icon">
                                                      <StaticImage
                                                        placeholder="none"
                                                        width="35"
                                                        height="35"
                                                        src="../images/icon-fcb-12.png"
                                                        className="attachment-full"
                                                        alt=""
                                                        loading="lazy"
                                                      />{" "}
                                                    </div>
                                                  </div>
                                                  <div className="item-holder">
                                                    <h3 className="item--title">
                                                      {
                                                        homePage.section3Block3Title
                                                      }
                                                    </h3>
                                                    <div className="item--description">
                                                      {
                                                        homePage.section3Block3Paragraph
                                                      }
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-a267d66 elementor-widget elementor-widget-cms_fancy_box"
                                            data-id="a267d66"
                                            data-element_type="widget"
                                            data-widget_type="cms_fancy_box.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-fancy-box layout3 box-icon-left ">
                                                <div className="inner-content">
                                                  <div className="item--icon icon-image">
                                                    <div className="inner-icon center-inter-icon">
                                                      <StaticImage
                                                        placeholder="none"
                                                        width="48"
                                                        height="36"
                                                        src="../images/icon-fcb-13.png"
                                                        className="attachment-full"
                                                        alt=""
                                                        loading="lazy"
                                                      />{" "}
                                                    </div>
                                                  </div>
                                                  <div className="item-holder">
                                                    <h3 className="item--title">
                                                      {
                                                        homePage.section3Block4Title
                                                      }
                                                    </h3>
                                                    <div className="item--description">
                                                      {
                                                        homePage.section3Block4Paragraph
                                                      }
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </section>
                                  <div
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-b410ac4 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                    data-id="b410ac4"
                                    data-element_type="section"
                                  >
                                    <div className="elementor-container elementor-column-gap-default ">
                                      <div
                                        className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-0b4fe1f"
                                        data-id="0b4fe1f"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-1bae692 cms-animation-bottom-to-top elementor-widget elementor-widget-cms_image_single"
                                            data-id="1bae692"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-image-single cms-animation-bottom-to-top2 ">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width="132"
                                                    height="116"
                                                    src="../images/image-home-1.png"
                                                    className="attachment-full size-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>

                        </div>
                      </div>
                    </div>
                  </article>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Index;
